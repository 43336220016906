/* eslint-disable max-lines */
import { SwiperProps } from 'swiper/react';
import { NormalizedVariant, NormalizedProduct, BadgeTypes } from '@ts/product';
import { CurrencyCode } from '@ts/shopify-storefront-api';
import type { ToastComponentProps } from '@components';

/***------ Single Values ------***/
export const ALL_ANNOUNCEMENTS = 'All';
export const ASSET_EXT_REGEX = /\.(png|gif|webp|jpg|jpeg|pjpg|tiff|heif|avif|bmp|svg)/gim;
export const BULK_ORDERS_FORM_SCRIPT = 'https://embed.typeform.com/next/embed.js';
export const DEFAULT_BASE_FRAME_SHAPE = 'Larkin';
export const FAQ_URL = 'https://paireyewearhelp.zendesk.com/hc/en-us';
export const EMAIL_VALIDATION_REG_EX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@([a-zA-Z0-9-]+\.)+([a-zA-Z0-9-]+){2,4}$/;
export const NUMBERS_VALIDATION_REG_EX = /^\d+$/;
export const GID_REG_EX = /[^\/]+$/g;
export const GREENHOUSE_IO_URL_JS = 'https://boards.greenhouse.io/embed/job_board/js?for=paireyewear';
export const GREENHOUSE_IO_BOARD_URL = 'https://boards.greenhouse.io/embed/job_board?for=paireyewear&amp';
export const GREENHOUSE_IO_APP_URL = 'https://boards.greenhouse.io/embed/job_app?for=paireyewear&amp';
export const GROUP_BY_DEFAULT_ATTRIBUTE = 'DEFAULT';
export const IMAGE_PLACEHOLDER = 'https://cdn.shopify.com/s/files/1/1147/9910/files/how-it-works_base-frame.svg?v=1676483839';
export const MIN_VALUE = 5;
export const EXTRA_SMALL_WIDTH = 375;
export const MEDIUM_WIDTH = 769;
export const MEDIUM_MAX_WIDTH = 768;
export const MOBILE_MAX_WIDTH = 576;
export const MEDIUM_LARGE_WIDTH = 960;
export const LARGE_WIDTH = 992;
export const EXTRA_LARGE_WIDTH = 1024;
export const EXTRA_EXTRA_LARGE_WIDTH = 1440;
export const PRIVACY_FORM_URL =
	'https://privacyportal-eu.onetrust.com/webform/d34a94c4-547b-4f22-88e2-72cd8e79be26/d767de47-b397-4158-82d0-4a05ef52c99f';
export const REFLECTIVE_ADDON_PRICE = 15;
export const SHOPIFY_CHECKOUT_PREFIX = 'https://shop.paireyewear.com/11479910/checkouts/';
export const UTM_MEDIUM = 'utm_medium';
export const UTM_MEDIUM_INFLUENCER = 'influencer';
export const UTM_MEDIUM_AUDIO = 'audio';
export const ALL_TOP_COLLECTIONS_LOADED_ON_BUILD = 9;
export const PAIR_EYEWEAR = 'Pair Eyewear';
export const TOP_FRAMES_PATH = '/top-frames';
export const ACCESSORIES_PATH = '/accessories';
export const MAXIMUM_VARIANTS_FOR_COLLAPSIBLE = 5;
export const DOUBLE_COLUMN_THRESHOLD = 35;
export const MAX_PRODUCTS_TO_SEND = 16;
// TODO remove instances of MARVEL once customizable options have been set
export const MARVEL_LABEL = 'MARVEL';

/***------ Locales ------***/
export const LOCALE_DICT: {
	[key: string]: {
		copy: string;
		countryCode: string;
		currency: string;
		currencyCode: CurrencyCode;
		label: string;
		selectorCopy: string;
	};
} = {
	'en-US': {
		copy: 'the United States',
		countryCode: 'US',
		currency: '$',
		currencyCode: CurrencyCode.Usd,
		label: 'United States',
		selectorCopy: 'USA',
	},
	'en-CA': {
		copy: 'Canada',
		countryCode: 'CA',
		currency: '$',
		currencyCode: CurrencyCode.Cad,
		label: 'Canada',
		selectorCopy: 'CA',
	},
	'en-AU': {
		copy: 'Australia',
		countryCode: 'AU',
		currency: '$',
		currencyCode: CurrencyCode.Aud,
		label: 'Australia',
		selectorCopy: 'AU',
	},
	'en-GB': {
		copy: 'the United Kingdom',
		countryCode: 'GB',
		currency: '£',
		currencyCode: CurrencyCode.Gbp,
		label: 'United Kingdom',
		selectorCopy: 'UK',
	},
};
export const LOCALE_CODES = {
	US: 'en-US',
	CA: 'en-CA',
	AU: 'en-AU',
	UK: 'en-GB',
	GB: 'en-GB',
};

/***------ Cookies ------***/
export const DISCOUNT_CODE_COOKIE = 'discount_code';
export const SHOPIFY_CART_ID_COOKIE = 'shopify_cartId';
export const SHOPIFY_CHECKOUT_ID_COOKIE = 'shopify_checkoutId';
export const SHOPIFY_CHECKOUT_URL_COOKIE = 'shopify_checkoutUrl';
export const SHOPIFY_CUSTOMER_TOKEN_COOKIE = 'shopify_customerToken';
export const SHOPIFY_COOKIE_EXPIRE = 30; // 30 days
export const SHOPIFY_COOKIE_EXPIRE_QUICK = 1; // 1 day
export const SHOPIFY_COOKIE_EXPIRE_WEEK = 7; // 7 days

/***------ 3rd Party Scripts ------***/
export const VTO_SRC = '//vto-advanced-integration-api.fittingbox.com/index.js';
export const YOTPO = {
	ACTIVITY_WIDGET_STYLE1_ID: '174304',
	ACTIVITY_WIDGET_STYLE2_ID: '174298',
	CAMPAIGNS_WIDGET_ID: '190231',
	VIP_TIERS_WIDGET_ID: '190232',
	REFER_A_FRIEND_WIDGET_ID: '190229',
	REWARDS_PAGE_WIDGET_ID: '853368',
	REWARDS_SCRIPT_URL: `https://cdn-widgetsrepository.yotpo.com/v1/loader/${process.env.NEXT_PUBLIC_YOTPO_GUID}`,
	REVIEWS_SCRIPT_URL: `//staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_API_KEY}/widget.js`,
} as const;

export const GLASSESON = {
	API_URL: 'https://api.glasseson.com/prod/',
	SCRIPT_URL: 'https://web.cdn.glasseson.com/glasseson-2.31.0.js',
	OPTIONS: {
		profileName: 'web',
		flow: 'pd',
		fullPage: false,
		width: 835,
		themeColor: '#080593',
		fontFamily: 'var(--poppins)',
	},
} as const;

/***------ Objects ------***/
export const DEMO_HANDLES = {
	women: 'womens-frames',
	men: 'mens-frames',
	kids: 'kids-frames',
	all: 'all-frames',
} as Record<(typeof DEFAULT_DEMOS)[number], string>;
export const ADULT_BASE_FRAME_HANDLES: Record<string, (typeof ADULT_BASE_FRAME_NAMES)[number]> = {
	'base-frame-3': 'Twain',
	'large-base': 'Larkin',
	'the-kirby': 'Kirby',
	'the-casper': 'Casper',
	'the-reese': 'Reese',
	'the-otis': 'Otis',
	'the-soto': 'Soto',
	'the-finley': 'Finley',
	'the-ella': 'Ella',
	'the-wanda': 'Wanda',
	'the-jessie': 'Jessie',
	'the-harper': 'Harper',
	'the-quinn': 'Quinn',
	'the-murphy': 'Murphy',
	'the-drew': 'Drew',
} as const;
export const KIDS_BASE_FRAME_HANDLES: Record<string, (typeof KIDS_BASE_FRAME_NAMES)[number]> = {
	'the-cedro': 'Cedro',
	'base-frame-2': 'Serra',
	'medium-rectangular-base': 'Otero',
	'base-frame-3': 'Twain',
	'large-base': 'Larkin',
} as const;

export const ALL_BASE_FRAME_HANDLES: Record<
	string,
	(typeof ADULT_BASE_FRAME_NAMES)[number] | (typeof KIDS_BASE_FRAME_NAMES)[number]
> = {
	'the-cedro': 'Cedro',
	'base-frame-2': 'Serra',
	'medium-rectangular-base': 'Otero',
	'base-frame-3': 'Twain',
	'large-base': 'Larkin',
	'the-kirby': 'Kirby',
	'the-casper': 'Casper',
	'the-reese': 'Reese',
	'the-otis': 'Otis',
	'the-soto': 'Soto',
	'the-finley': 'Finley',
	'the-ella': 'Ella',
	'the-wanda': 'Wanda',
	'the-jessie': 'Jessie',
	'the-harper': 'Harper',
	'the-quinn': 'Quinn',
	'the-murphy': 'Murphy',
	'the-drew': 'Drew',
} as const;

export const FRAME_COLOR_IMAGE_LINKS = {
	'Black': 'https://cdn.shopify.com/s/files/1/1147/9910/files/Black-Swatch.png?v=1682957016',
	'Blue Clear': 'https://cdn.shopify.com/s/files/1/1147/9910/files/Blue-Clear-Swatch.png?v=1682957005',
	'Blue Tortoise': 'https://cdn.shopify.com/s/files/1/1147/9910/files/Blue-Tortoise-Swatch.png?v=1682956995',
	'Crystal Clear': 'https://cdn.shopify.com/s/files/1/1147/9910/files/Crystal-Clear-Swatch.png?v=1682956984',
	'Pink Clear': 'https://cdn.shopify.com/s/files/1/1147/9910/files/Pink-Clear-Swatch.png?v=1682956972',
	'Tortoise': 'https://cdn.shopify.com/s/files/1/1147/9910/files/Tortoise-Swatch.png?v=1682956826',
	'Clear': 'https://cdn.shopify.com/s/files/1/1147/9910/files/Crystal-Clear-Swatch.png?v=1682956984',
	'Mixed Material Black Gold Metal': 'https://cdn.shopify.com/s/files/1/1147/9910/files/Black-Swatch.png?v=1682957016',
	'Mixed Material Crystal Clear Silver Metal':
		'https://cdn.shopify.com/s/files/1/1147/9910/files/Crystal-Clear-Swatch.png?v=1682956984',
} as const;

// @george ur mind...
export enum FACE_SHAPES {
	DIAMOND = 'diamond_face',
	HEART = 'heart_face',
	OBLONG = 'oblong_face',
	OVAL = 'oval_face',
	ROUND = 'round_face',
	SQUARE = 'square_face',
	TRIANGLE = 'triangle_face',
}

export const PDP_CTA_IMAGES = {
	350: '//cdn.shopify.com/s/files/1/1147/9910/files/free-shipping-cta--mobile_350x.png?v=2047124633846615643',
	400: '//cdn.shopify.com/s/files/1/1147/9910/files/free-shipping-cta--mobile_400x.png?v=2047124633846615643',
	767: '//cdn.shopify.com/s/files/1/1147/9910/files/free-shipping-cta--desktop_767x.png?v=15397541059557975908',
	default: '//cdn.shopify.com/s/files/1/1147/9910/files/free-shipping-cta--desktop.png?v=15397541059557975908',
} as const;

export const PRODUCT_TYPES = {
	ACCESSORY: 'ACCESSORY',
	BASE_FRAME: 'BASE_FRAME',
	BASE_FRAME_SR: 'BASE_FRAME_SR',
	GIFT_CARD: 'Gift Card',
	INSURANCE: 'INSURANCE',
	LENS: 'LENS',
	MISC: 'MISC',
	PACKAGING: 'PACKAGING',
	TOP_FRAME: 'TOP_FRAME', // Note: The actual Shopify type is 'TOP_FRAME__<COLLECTION>'
} as const;

export const PRODUCT_TYPE_NAMES = {
	[PRODUCT_TYPES.BASE_FRAME]: 'Base Frame',
	[PRODUCT_TYPES.ACCESSORY]: 'Accessory',
	[PRODUCT_TYPES.TOP_FRAME]: 'Top Frame',
	TOP_FRAME_BUNDLE: 'Bundles',
	TOP_FRAME__SUNTOP: 'Sun Tops',
	frame: 'Top Frame',
} as const;

export const SUBMISSION_METHODS = {
	REMIND: 'remind',
	PHOTO: 'photo',
	DOCTOR: 'doctor',
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS: any = ['jpg', 'jpeg', 'png', 'pdf', 'heic'] as const;

export const PRESCRIPTIONS = {
	SINGLE_VISION: 'single-vision',
	PROGRESSIVES: 'progressives',
	READERS: 'readers',
	NON_PRESCRIPTION: 'non-prescription',
	PRESCRIPTION: 'base-prescription',
} as const;

export const PRESCRIPTION_LABELS = {
	SUCCESS: 'Prescription Received!',
	MISSING_PD: 'Prescription received but missing PD',
	MISSING: 'Select an option below to complete your order.',
};

export const LENSES = {
	BLUE_LIGHT_FILTERING: 'blue-light-filtering',
	PREMIUM_PLUS: 'premium-ultra-thin',
	LIGHT_RESPONSIVE: 'light-responsive',
} as const;

export const ORDER_HISTORY_ITEM_SEQUENCE = {
	[PRODUCT_TYPES.BASE_FRAME]: 0,
	[PRODUCT_TYPES.LENS]: 1,
	[PRODUCT_TYPES.TOP_FRAME]: 2,
	[PRODUCT_TYPES.ACCESSORY]: 3,
	[PRODUCT_TYPES.INSURANCE]: 4,
	[PRODUCT_TYPES.PACKAGING]: 5,
	[PRODUCT_TYPES.GIFT_CARD]: 6,
	[PRODUCT_TYPES.MISC]: 8,
} as const;

export const COURIER_OPTIONS = {
	DHL: 'DHL',
	UPS: 'UPS',
	USPS: 'USPS',
	FEDEX: 'FEDEX',
	LANDMARK: 'LANDMARK',
	CANADA_POST: 'CANADA',
} as const;

export const ACCOUNT_TABS = {
	MY_FAVORITES: 'My Favorites',
	MY_COLLECTION: 'My Collection',
	MY_ORDERS: 'My Orders',
	MY_REWARDS: 'My Rewards',
};

export const BASE_FRAME_SIZING_OPTIONS: Record<(typeof BASE_FRAME_NAMES)[number], (typeof BASE_FRAME_SIZINGS)[number]> = {
	Cedro: 'Extra-Narrow',
	Serra: 'Extra-Narrow',
	Twain: 'Extra-Narrow',
	Otero: 'Extra-Narrow',
	Larkin: 'Narrow',
	Reese: 'Narrow',
	Casper: 'Medium',
	Soto: 'Medium',
	Finley: 'Narrow',
	Ella: 'Narrow',
	Kirby: 'Medium',
	Otis: 'Medium',
	Wanda: 'Medium',
	Jessie: 'Wide',
	Harper: 'Wide',
	Murphy: 'Wide',
	Quinn: 'Extra-Wide',
	Drew: 'Extra-Wide',
};

export const BASE_FRAME_AGE_RANGES = {
	Cedro: '5-9yrs',
	Serra: '5-9yrs',
	Otero: '8-12yrs',
	Twain: '9-Adult',
	Larkin: '9-Adult',
};

export const DELETE_BASE_FRAME = {
	TITLE: 'Are you sure?',
	LABEL: 'You are about to remove',
	PROMPT: 'Doing this will also remove your lens selections. Please confirm.',
};

/***------ Arrays ------***/
export const BASE_FRAME_NAMES = [
	'Drew',
	'Murphy',
	'Quinn',
	'Harper',
	'Jessie',
	'Kirby',
	'Wanda',
	'Otis',
	'Soto',
	'Casper',
	'Ella',
	'Finley',
	'Reese',
	'Larkin',
	'Otero',
	'Twain',
	'Serra',
	'Cedro',
] as const;

export const ADULT_BASE_FRAME_NAMES = [
	'Kirby',
	'Larkin',
	'Wanda',
	'Finley',
	'Casper',
	'Reese',
	'Ella',
	'Otis',
	'Soto',
	'Twain',
	'Quinn',
	'Harper',
	'Murphy',
	'Drew',
	'Jessie',
] as const;
export const KIDS_BASE_FRAME_NAMES = ['Cedro', 'Serra', 'Otero', 'Twain', 'Larkin'] as const;
export const BASE_FRAME_SIZINGS = ['Extra-Narrow', 'Narrow', 'Medium', 'Wide', 'Extra-Wide'] as const;

export const METAL_BASE_FRAMES = ['Kirby', 'Wanda', 'Finley', 'Murphy', 'Larkin'] as const;

export const DEFAULT_CATEGORIES = ['eyeglasses', 'sunglasses', 'blue-light'] as const;
export const DEFAULT_DEMOS = ['women', 'men', 'kids', 'all'] as const;
export const READER_STRENGTHS = ['0.25', '0.50', '0.75', '1.00', '1.25', '1.50', '1.75', '2.00', '2.25', '2.50', '2.75'] as const;
export const ORDER_FILTER_OPTIONS = [
	{ copy: 'last 3 months', value: 90 },
	{ copy: 'last 6 months', value: 180 },
	{ copy: 'past year', value: 365 },
	{ copy: 'all-time', value: null },
];

/**
 * START OF POSSIBLE REFACTOR
 */
export const IDEAL_FOR_FACE_SHAPES = {
	'base-frame-2':
		'The Serra looks fantastic on most younger kids’ faces, and they particularly suit an oval, square, or oblong shape.',
	'base-frame-3':
		'The Twain looks fantastic on most adult and older kids’ faces, and they particularly suit an oval or round shape.',
	'the-casper':
		'The Casper looks fantastic on most adult faces, and they particularly suit an oval, round, heart, or diamond shape.',
	'the-cedro': 'The Cedro looks fantastic on most younger kids’ faces, and they particularly suit an oval or round shape.',
	'the-drew': 'The Drew looks fantastic on most adult faces, and they particularly suit an oval, round, or triangle shape.',
	'the-ella':
		'The Ella looks fantastic on most adult faces, and they particularly suit an oval, round, square, heart, diamond, or triangle shape.',
	'the-finley': 'The Finley looks fantastic on most adult faces, and they particularly suit an oval or round shape.',
	'the-harper':
		'The Harper looks fantastic on most adult faces, and they particularly suit an oval, round, heart, diamond, or triangle shape.',
	'the-jessie':
		'The Jessie looks fantastic on most adult faces, and they particularly suit an oval, round, heart, diamond, or triangle shape.',
	'the-kirby': 'The Kirby looks fantastic on most adult faces, and they particularly suit an oval or round shape.',
	'large-base':
		'The Larkin looks fantastic on most adult and older kids’ faces, and they particularly suit an oval or round shape.',
	'medium-rectangular-base':
		'The Otero looks fantastic on most older kids’ faces, and they particularly suit an oval or round shape.',
	'the-murphy':
		'The Murphy looks fantastic on most adult faces, and they particularly suit an oval, heart, oblong, or triangle shape.',
	'the-otis': 'The Otis looks fantastic on most adult faces, and they particularly suit an oval, square, or oblong shape.',
	'the-quinn':
		'The Quinn looks fantastic on most adult faces, and they particularly suit an oval, round, heart, diamond, or triangle shape.',
	'the-reese': 'The Reese looks fantastic on most adult faces, and they particularly suit an oval, square, or diamond shape.',
	'the-soto': 'The Soto looks fantastic on most adult faces, and they particularly suit an oval, square, or oblong shape.',
	'the-wanda':
		'The Wanda looks fantastic on most adult faces, and they particularly suit an oval, round, square, heart, diamond, or triangle shape.',
} as const;

export const SUN_TOPS_PROPOSITIONS_LIST = [{ id: 'polarized-lenses' }, { id: 'front-hard-coating' }, { id: 'uva-uvb-block' }];

export const LOGGED_IN_MARKETING_PROPOSITIONS = [
	{ id: 'logged_out_marketing_block_2', cmsSlug: 'logged-out-marketing-block-2' },
	{ id: 'logged_in_marketing_block_2', cmsSlug: 'logged-in-marketing-block-2' },
];

export const LOGGED_OUT_MARKETING_PROPOSITIONS = [
	{ id: 'logged_out_marketing_block_1', cmsSlug: 'logged-out-marketing-block-1' },
	{ id: 'logged_out_marketing_block_2', cmsSlug: 'logged-out-marketing-block-2' },
];

export const ABOUT_VALUE_PROPOSITIONS = {
	[PRODUCT_TYPES.BASE_FRAME]: [
		{
			id: 'base_value_prop_one',
			cmsSlug: 'ever-changing-eyewear',
		},
		{
			id: 'base_value_prop_two',
			cmsSlug: 'strength-and-style',
		},
		{
			id: 'base_value_prop_three',
			cmsSlug: 'a-frame-for-every-face',
		},
	],
	[PRODUCT_TYPES.TOP_FRAME]: [
		{
			id: 'top_value_prop_one',
			cmsSlug: 'switch-up-your-style',
		},
		{
			id: 'top_value_prop_two',
			cmsSlug: 'the-magic-is-in-the-magnets',
		},
		{
			id: 'top_value_prop_three',
			cmsSlug: 'new-tops-drop-every-week',
		},
	],
	['METAL_TEMPLE']: [
		{
			id: 'metal_temple_value_prop_one',
			cmsSlug: 'metal-temple-1',
		},
		{
			id: 'metal_temple_value_prop_two',
			cmsSlug: 'metal-temple-2',
		},
		{
			id: 'metal_temple_value_prop_three',
			cmsSlug: 'metal-temple-3',
		},
	],
};

export const ALSO_WORTH_CHECKOUT_COPY = {
	'glasses-case': 'Protect your Pair in our secure and durable, yet lightweight case.',
	'top-frame-case': 'Take two more of your favorite top frames on-the-go with our ultra-portable case.',
	'wall-hanger':
		'Our wall hanger allows you to magically display up to 5 top frames and even has a pocket at the bottom for your base frame!',
	'cleaning-kit': 'The all-in-one kit includes lens cleaning spray, a micro-fiber cloth, and a carrying kit.',
};
/**
 * END OF POSSIBLE REFACTOR
 */

export const RX_FIELDS = ['sphere', 'cylinder', 'add', 'prism', 'pd'];
export const ORDER_FILTER_PLACEHOLDER = ORDER_FILTER_OPTIONS[ORDER_FILTER_OPTIONS.length - 1].copy;

/***------ COPY ------***/
export const ADD_TO_CART = 'Add to Cart';
export const BF_MY_TOPS_COPY = 'Complete your Pair with some top frames!';
export const PAIR_CARE_COPY = 'PairCare covers the full cost of the base frame and any lens add-ons.';
export const PREMIUM_ULTRA_THIN_COPY =
	'These 1.67 high-index lenses are recommended for complex prescriptions but are not recommended for people under 18 as they are less impact resistant.';
export const SINGLE_VISION_COPY =
	'I need basic vision correction–I’m either nearsighted, farsighted, or have astigmatism. I’d love my lenses to be scratch resistant and anti-reflective.';
export const PROGRESSIVES_COPY =
	'I want a single lens that provides sharper correction for multiple fields of vision with an easier adjustment process. I’m looking for something scratch resistant and anti-reflective with a smoother transition between far, medium, and near views.';
export const READERS_COPY = 'Extra magnification when reading small text or observing objects more closely would be helpful.';
export const NON_PRESCRIPTION_COPY =
	'I already see 20-20 and don’t need glasses, but scratch resistant, anti-reflective fashion frames would be fun!';
export const FREE_SHIPPING_COPY = `We want you to love your look! With a free 30-day return and exchange policy, you'll receive a Pair you're proud to wear.`;

export const GIFT_CARD_COPY = {
	TWENTY_FIVE: '$25 - A fun Top Frame of their choice',
	FIFTY: '$50 - Two totally new Tops',
	HUNDRED: '$100 - Rx Base Frames, a Top, and a wall hanger',
	TWO_HUNDRED: '$200 - Rx blue-light Base Frames, two Tops, + PAIRCare',
};

export const LENSES_SUPPORT_COPY = {
	blueLight: '96% customer satisfaction with this additional lens',
	premiumPlus: '93% Customer Satisfaction w/ This Lens Add-On',
	both: '95% Customer Satisfaction w/ These Lens Add-Ons',
};
export const BF_PRESCRIPTIONS_STEP_TITLE = '1. Select Your Lenses';
export const BF_LENSES_STEP_TITLE = '2. Customize Your Lenses';
export const BF_LENS_PACKAGES_STEP_TITLE = '2. Choose Your Lens Type';
export const EDIT_LENS_SELECTION = 'To edit your lens selection, click on the edit button on the top of the cart.';
export const READERS_STRENGTH_COPY = 'Select Strength';
export const LOAD_MORE = 'Load More';

export const BUILD_FLOW_HEADERS = {
	prescriptions: BF_PRESCRIPTIONS_STEP_TITLE,
	fields: 'Check Off Your Prescription Details',
	form: 'Enter Your Prescription Details',
	review: 'Review Your Prescription',
	nonPrescription: { default: 'Recommended Lenses', sendLater: 'Select a Prescription Type' },
	lenses: {
		default: 'Choose Lens Upgrades',
		[PRESCRIPTIONS.READERS]: 'Configure Readers',
	},
	customLenses: "What's My Prescription Type?",
};

export const BF_CART_ACTIONS_COPY: Record<string, Record<'default' | string, string>> = {
	colors: { default: 'Choose Lens Type' },
	prescriptions: { default: 'Choose Lens Upgrades', choose: 'Choose Top Frames', custom: 'See my Custom Lenses' },
	fields: { default: 'Enter Prescription Details' },
	form: { default: 'Review' },
	review: { default: 'Choose Lens Upgrades', recommended: 'View Recommended Lenses' },
	nonPrescription: {
		default: 'Choose Lens Upgrades',
		continue: 'Continue with',
		frames: 'Choose Top Frames',
		select: 'Please Select a Prescription',
	},
	lenses: { default: 'Choose Top Frames' },
	collections: { default: ADD_TO_CART, editMode: 'Update Cart' },
	submitted: { default: 'Adding' },
};

/***------ DUMMY DATA ------***/
export const EMPTY_VARIANT: NormalizedVariant = {
	id: '',
	handle: '',
	type: 'TOP_FRAME',
	option: '',
	name: '',
	sku: '',
	availableForSale: false,
	requiresShipping: false,
	image: {
		__typename: 'Image',
		altText: '',
		height: 0,
		id: '',
		originalSrc: undefined,
		src: undefined,
		transformedSrc: undefined,
		url: IMAGE_PLACEHOLDER,
		width: 0,
	},
	price: {
		amount: 0,
		currencyCode: '',
	},
	metafields: {},
};

export const EMPTY_PRODUCT: NormalizedProduct = {
	name: '',
	availableForSale: false,
	totalInventory: 0,
	id: '',
	path: '',
	slug: '',
	handle: '',
	price: {
		amount: 0,
		currencyCode: '',
	},
	type: PRODUCT_TYPES.BASE_FRAME,
	images: [],
	variants: [{ ...EMPTY_VARIANT }],
	options: [],
	metafields: {},
	tags: [],
};

export const VIEW_ALL_TOPS_DEFAULT = {
	image: { url: 'https://cdn.shopify.com/s/files/1/1147/9910/files/all-tops-module_homepage_desktop.png?v=1655168011' },
	title: 'View All Tops',
	handle: '',
	metafields: {
		tileImage: {
			url: 'https://cdn.shopify.com/s/files/1/1147/9910/files/all-tops-module_homepage_desktop.png?v=1655168011',
		},
	},
};

export const BADGE_TYPES: { [key: string]: BadgeTypes } = {
	LIMITED: {
		name: 'limited',
		displayText: 'Limited Edition',
		backgroundColor: '#fbe324',
		color: 'black',
	},
	SOON: {
		name: 'coming-soon',
		displayText: 'Coming Soon',
		backgroundColor: '#3431dc',
		color: 'white',
	},
	LAUNCHED: {
		name: 'just-launched',
		displayText: 'Just Launched!',
		backgroundColor: '#fbe324',
		color: 'black',
	},
	NEW: {
		name: 'new',
		displayText: 'New!',
		backgroundColor: '#00853c',
		color: 'white',
	},
	BEST_SELLER: {
		name: 'badge-best',
		displayText: 'Best Seller',
		backgroundColor: '#3431dc',
		color: 'white',
	},
	BACK_IN_STOCK: {
		name: 'back-in-stock',
		displayText: 'Back in Stock',
		backgroundColor: '#3431dc',
		color: 'white',
	},
	TOP_RATED: {
		name: 'badge-top-rated',
		displayText: 'Top Rated',
		backgroundColor: '#3431dc',
		color: 'white',
	},
	PREMIUM: {
		name: 'premium',
		displayText: 'Premium',
		backgroundColor: '#A3E6F0',
		color: 'black',
	},
	BASE_FRAME_SIZE: {
		name: 'base-frame-size',
		displayText: 'Base Frame Size',
		backgroundColor: '#fbe324',
		color: 'black',
	},
	PREVIEWING: {
		name: 'previewing',
		displayText: 'Previewing',
		backgroundColor: '#3431dc',
		color: 'white',
	},
	CLICK_PREVIEW: {
		name: 'click-to-preview',
		displayText: 'Click to Preview',
		backgroundColor: '#efedee',
		color: '#666163',
	},
	METAL: {
		name: 'metal-temple',
		displayText: 'Metal',
		backgroundColor: '#ff510e',
		color: 'white',
	},
} as const;

export const COUNTDOWN_COPY = {
	LAUNCH: 'Time remaining until drop',
	EXPIRY: 'Collection leaving soon!',
} as const;

export const REFER_WIDGET_COPY = {
	TITLE: 'Refer a Friend',
	HEADING: 'Give $25, Get $25',
	BODY: 'Give your friends $25 off their first order of $60 (after discounts) and get $25 (in points) for each successful referral!',
	CTA_TEXT: 'Start Referring',
	CTA_LINK: '/refer',
	IMAGE_URL: 'https://cdn.shopify.com/s/files/1/1147/9910/files/Frame_1039.png?v=1652377247',
};

export const BYOB_WIDGET_COPY = {
	TITLE: 'Bundle & Save',
	HEADING: 'Give $25, Get $25',
	BODY: 'Build your own bundle of 3 to 4 Top Frames & save up to 15%',
	CTA_TEXT: 'Build Your Own Bundle',
	CTA_LINK: '/build-your-own-bundle',
	IMAGE_URL: 'https://cdn.shopify.com/s/files/1/1147/9910/files/BYOB-Nav-Block_No-Overlay.jpg?v=1725647508',
};

export const ALL_TOPS_WIDGET_COPY = {
	TITLE: 'All Tops',
	HEADING: {
		'en-AU': 'From $35',
		'en-GB': 'From £25',
	},
	CTA_TEXT: 'Shop All Tops',
	CTA_LINK: '/top-frames',
	IMAGE_URL: 'https://cdn.shopify.com/s/files/1/1147/9910/files/all_tops_widget.jpg?v=1709887131',
};

export const LENSES_WIDGET_COPY = {
	TITLE: 'Your new look starts with clear sight.',
	CTA_TEXT: 'Shop Now',
	CTA_LINK: '/eyeglasses/all',
	IMAGE_URL: 'https://cdn.shopify.com/s/files/1/1147/9910/files/yellow_malak.png?v=1722604089',
};

export const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'] as const;

export const CLICK_ID_PARAMS = [
	'gclid', // Google click ID
	'fbclid', // Facebook click ID
	'ttclid', // TikTok click ID
	'irclickid', // Impact Radius click ID
] as const;

export const ATTRIBUTION_PARAMS = [...UTM_PARAMS, ...CLICK_ID_PARAMS] as const;

export const NEXT_APP_ENV = {
	PROD: 'production',
	DEV: 'development',
};

export const TOAST: Record<string, ToastComponentProps> = {
	READERS_STRENGTH_ERROR: {
		type: 'error',
		title: 'Check your Readers Strength!',
		message: 'Select the strength of your Readers to continue',
		ctaLabel: 'Got It',
		actionRequired: true,
	},
	EDIT_MODE: {
		type: 'info',
		title: 'Edit Your Pair',
		message:
			'Please proceed through “Build & Purchase” to make changes to the chosen Base Frame in your cart.  Select “Update Cart” to save your most recent edits!',
		ctaLabel: 'Got It',
		showTimer: true,
	},
	VARIANT_UNAVAILABLE: {
		type: 'error',
		title: 'Variant Unavailable',
		message: 'The variant is currently unavailable for your base frame.',
	},
	UPLOAD_RX_ERROR: {
		type: 'error',
		title: 'Whoops!',
		message: `
				We're having trouble saving your information. Please continue to checkout, and we'll follow up via email after your order has been placed!
				`,
	},
	LAST_ACTION_ERROR: {
		type: 'error',
		title: 'Whoops!',
		message: `We're having trouble with your last action. Please try again later.`,
	},
	PD_NOT_NEEDED: {
		type: 'info',
		title: 'PD not Needed',
		message: "Looks like this order has PD measured or doesn't need PD.",
	},
	PD_IS_MEASURED: {
		type: 'info',
		title: 'PD Already Measured',
		message: 'This order has the PD measured.',
	},
	ORDER_NOT_FOUND: {
		type: 'info',
		title: 'No Order Found',
		message: 'We could not find this order in our system.',
	},
};

// New Build Flow Context
export enum BUILD_FLOW_STEPS {
	COLOR,
	LENS,
	CUSTOMIZE,
	TOP_FRAMES,
	SUBMIT,
}

export enum BUILD_FLOW_STEPS_LENS_EXPANSION {
	COLOR,
	LENS,
	PACKAGE,
	CUSTOMIZE,
	TOP_FRAMES,
	SUBMIT,
}

export enum LENSES_PACKAGES {
	BASIC = 'Basic',
	STANDARD = 'Standard',
	PREMIUM = 'Premium',
}

export enum FRAME_COLORS {
	MIX_CRYSTAL_SILVER = 'Mixed Material Crystal Clear Silver Metal',
	MIX_BLACK_GOLD = 'Mixed Material Black Gold Metal',
	CRYSTAL_CLEAR = 'Crystal Clear',
	BLACK = 'Black',
	BLUE_CLEAR = 'Blue Clear',
	BLUE_TORTOISE = 'Blue Tortoise',
	TORTOISE = 'Tortoise',
	PINK_CLEAR = 'Pink Clear',
}

export enum FRAME_COLOR_HANDLES {
	MIX_BLACK_GOLD = 'mixed-material-black-gold-metal',
	MIX_CRYSTAL_SILVER = 'mixed-material-crystal-clear-silver-metal',
	BLACK = 'black',
	BLUE_CLEAR = 'blue-clear',
	BLUE_TORTOISE = 'blue-tortoise',
	CRYSTAL_CLEAR = 'crystal-clear',
	PINK_CLEAR = 'pink-clear',
	TORTOISE = 'tortoise',
}

export enum NEW_BASE_FRAME_NAMES {
	CEDRO = 'Cedro',
	SERRA = 'Serra',
	OTERO = 'Otero',
	TWAIN = 'Twain',
	LARKIN = 'Larkin',
	KIRBY = 'Kirby',
	CASPER = 'Casper',
	REESE = 'Reese',
	OTIS = 'Otis',
	SOTO = 'Soto',
	FINLEY = 'Finley',
	ELLA = 'Ella',
	WANDA = 'Wanda',
	JESSIE = 'Jessie',
	HARPER = 'Harper',
	QUINN = 'Quinn',
	MURPHY = 'Murphy',
	DREW = 'Drew',
}

export enum LENS_COLORS {
	BLACK = 'Black',
	BROWN = 'Brown',
	GREEN = 'Green',
	SILVER_REFLECTIVE = 'Silver Reflective',
	PINK_REFLECTIVE = 'Pink Reflective',
	BLUE_REFLECTIVE = 'Blue Reflective',
	GREEN_REFLECTIVE = 'Green Reflective',
}

export enum LENS_COLOR_HANDLES {
	BROWN = 'brown',
	GREEN = 'green',
	SILVER_REFLECTIVE = 'silver-reflective',
	PINK_REFLECTIVE = 'pink-reflective',
	BLUE_REFLECTIVE = 'blue-reflective',
	GREEN_REFLECTIVE = 'green-reflective',
	BLACK = 'black', // Black is last, so it gets analyzed last when a composed handle is obtained in PDP.
}

export enum CUSTOMER_JOURNEYS {
	DEFAULT = 'eyeglasses',
	EYEGLASSES = 'eyeglasses',
	SUNGLASSES = 'sunglasses',
	BLUELIGHT = 'blue-light',
}

/**
 * Enumerates the product handles associated with each RX type.
 * Before SKU-Restructuring, these values were used to add products to the cart,
 * and as unique identifiers for each option.
 *
 * Post-SR, the `RX_TYPE` enum is used as an identifier,
 * since it matches the variant options defined in Shopify.
 * However, these values are still used for Heap event tracking
 * */
export enum RX_TYPE_ID {
	SINGLE_VISION = 'single-vision',
	PROGRESSIVES = 'progressives',
	READERS = 'readers',
	NON_PRESCRIPTION = 'non-prescription',
	PRESCRIPTION = 'base-prescription',
}

export enum LENS_UPGRADES {
	BLUE_LIGHT_FILTERING = 'blue-light-filtering',
	PREMIUM_PLUS = 'premium-ultra-thin',
	LIGHT_RESPONSIVE = 'light-responsive',
}

export enum BUILD_FLOW_STEP_DIRECTION {
	PREV,
	NEXT,
}

export const RX_TYPE_TEXT = {
	[RX_TYPE_ID.SINGLE_VISION]: 'Single-Vision',
	[RX_TYPE_ID.PROGRESSIVES]: 'Progressive Lenses',
	[RX_TYPE_ID.READERS]: 'Readers',
	[RX_TYPE_ID.NON_PRESCRIPTION]: 'Non-Prescription',
	[RX_TYPE_ID.PRESCRIPTION]: 'Prescription',
};

export const BUILD_FLOW_BACK_TEXT = {
	BACK: 'Back',
	EXIT: 'Exit',
};

export const BUILD_FLOW_NEXT_TEXT = {
	[BUILD_FLOW_STEPS.COLOR]: 'Choose Lens Type',
	[BUILD_FLOW_STEPS.LENS]: 'Choose Lens Upgrades',
	[BUILD_FLOW_STEPS.CUSTOMIZE]: 'Choose Top Frames',
	[BUILD_FLOW_STEPS.TOP_FRAMES]: ADD_TO_CART,
	[BUILD_FLOW_STEPS.SUBMIT]: 'Adding',
	EDIT_MODE: 'Update Cart',
};

export const BUILD_FLOW_NEXT_TEXT_LENS_EXPANSION = {
	[BUILD_FLOW_STEPS_LENS_EXPANSION.COLOR]: 'Choose Lens Type',
	[BUILD_FLOW_STEPS_LENS_EXPANSION.LENS]: 'Make a Selection',
	[BUILD_FLOW_STEPS_LENS_EXPANSION.PACKAGE]: 'Make a Selection',
	[BUILD_FLOW_STEPS_LENS_EXPANSION.CUSTOMIZE]: 'Choose Top Frames',
	[BUILD_FLOW_STEPS_LENS_EXPANSION.TOP_FRAMES]: ADD_TO_CART,
	[BUILD_FLOW_STEPS_LENS_EXPANSION.SUBMIT]: 'Adding',
	EDIT_MODE: 'Update Cart',
};

export const LENS_PACKAGES_COPY = {
	[LENSES_PACKAGES.BASIC]: {
		lozenge: null,
		copy: 'Lenses with optical clarity.',
	},
	[LENSES_PACKAGES.STANDARD]: {
		lozenge: null,
		copy: 'Lenses with optical clarity and high-impact resistance.',
	},
	[LENSES_PACKAGES.PREMIUM]: {
		lozenge: 'Popular Upgrade',
		copy: 'Lenses with optical clarity up to 35% thinner than basic. Best for high prescriptions (above +3.00 and below -6.00).',
	},
};

export const ALTERNATE_PREMIUM_PLUS_COPY =
	'Thins out your lenses by up to 35%. Best for high prescriptions (above +3.00 and below -6.00).';

export const BASE_FRAME_COST = {
	'en-US': 60,
	'en-CA': 95,
	'en-GB': 79,
	'en-AU': 139,
};

export const METAL_BASE_FRAME_COST = {
	'en-US': 85,
	'en-CA': 145,
	'en-GB': 110,
	'en-AU': 195,
};

export const MIX_MATERIAL_SWATCHES_OPTIONS = [
	{
		acetate: FRAME_COLORS.CRYSTAL_CLEAR,
		metal: FRAME_COLORS.MIX_CRYSTAL_SILVER,
		label: 'Metal',
	},
	{
		acetate: FRAME_COLORS.BLACK,
		metal: FRAME_COLORS.MIX_BLACK_GOLD,
		label: 'Metal',
	},
	{
		acetate: FRAME_COLORS.BLUE_CLEAR,
		metal: null,
		label: null,
	},
	{
		acetate: FRAME_COLORS.BLUE_TORTOISE,
		metal: null,
		label: null,
	},
	{
		acetate: FRAME_COLORS.TORTOISE,
		metal: null,
		label: null,
	},
	{
		acetate: FRAME_COLORS.PINK_CLEAR,
		metal: null,
		label: null,
	},
];

export enum REFINEMENT_FILTER_COLORS {
	BLACK = '#1B1B1B',
	BLUE = '#3431DC',
	BROWN = '#975029',
	GREEN = '#03cc5e',
	GREY = '#C4C4C4',
	ORANGE = '#FF7E4D',
	PINK = '#F38BB6',
	PURPLE = '#7B00C7',
	RAINBOW = '#FFFFFF', // Placeholder
	RED = '#FF0000',
	TAN = '#DEBE8F',
	WHITE = '#FFFFFF',
	YELLOW = '#ffec56',
	'BLUE CLEAR' = '#FFFFFF',
	'BLUI TORTOISE' = '#FFFFFF',
	'CRYSTAL CLEAR' = '#FFFFFF',
	'PINK CLEAR' = '#FFFFFF',
	'TORTOISE' = '#FFFFFF',
	'CLEAR' = '#FFFFFF',
}

export const REFINEMENT_FRAME_IMAGE_LINKS = {
	'BLACK':
		'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_BLACKSUNTOP_21_CLASSICSUNTOP_3D_aa954aa7-628f-4aa6-b441-302e0c359416.png',
	'BLUE': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_PEACOCKOMBRE_23_PREMIUM_3D.png',
	'BROWN': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_TORTOISE_21_TORTOISE_3DS.png',
	'GREEN': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_FORESTGREEN_21_SOLIDCLASSIC_3D.png',
	'GREY': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_SLATEDIPDYE_21_CLASSICDESIGN_3D.png',
	'ORANGE': 'https://cdn.shopify.com/s/files/1/1147/9910/files/LARKIN_BURNTORANGE_23_FALLSOLIDS_3D.png',
	'PINK': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_ROSEGOLDSPARKLE_23_SPARKLESUNTOP_3D_9cbdf530-94a2-4afc-af45-6ef1f5c8760c.png',
	'PURPLE':
		'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_GALAXY_21_CLASSICDESIGN_3D_1d943499-51d2-497e-a732-f6d13748a647.png',
	'RAINBOW':
		'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_RAINBOWTIEDYE_21_CLASSICDESIGN_3D_03fccf61-b337-4ce8-9769-c619a37621bd.png',
	'RED': 'https://cdn.shopify.com/s/files/1/1147/9910/files/LARKIN_RUBY_23_SPARKLE_3D.png',
	'TAN': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_MAURADERSMAP_22_HARRYPOTTER_3D.png',
	'WHITE':
		'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_WHITE_21_SOLIDCLASSIC_3D_4eb5ef61-8825-43c0-9d46-cee4235ebfb5.png',
	'YELLOW': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_CHAMPAGNESPARKLE_23_SPARKLESUNTOP_3D.png',
	'ANIMALS': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_PEACOCKSCALLOPS_23_FRIDA_3D.png',
	'FLORALS': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_LILLYLATTICE_23_FRIDA_3D.png',
	'FOOD & DRINKS': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_COKE_22_COCACOLA_3D.png',
	'GRADIENT': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_OCEANBLUE_21_CLASSICDESIGN_3D.png',
	'MOVIES & MUSIC': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_WONDERWOMAN_21_DCCOMICS_3D.png',
	'PATTERN':
		'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_GALAXY_21_CLASSICDESIGN_3D_1d943499-51d2-497e-a732-f6d13748a647.png',
	'PLAID': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_BUFFALOPLAID_21_HARVEST_3D.png',
	'PRINTS': 'https://cdn.shopify.com/s/files/1/1147/9910/files/LARKIN_MAHOGANYWOODGRAIN_23_PREMIUM_3D.png',
	'SOLIDS':
		'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_RED_21_SOLIDCLASSIC_3D_9e458a1f-b725-4553-8308-2723a458c2a9.png',
	'SPARKLE': 'https://cdn.shopify.com/s/files/1/1147/9910/files/LARKIN_HOTPINK_23_SPARKLE_3D.png',
	'SPLATTERS': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_BLACKWHITESPLATTER_21_CLASSICDESIGN__3D.png',
	'SPLITS': 'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_BLACKANDWHITE_21_CLASSICDESIGN_3D.png',
	'SPORTS': 'https://cdn.shopify.com/s/files/1/1147/9910/files/LARKIN_ASTROSHOMERUN_21_MLB_3D.png',
	'STRIPES':
		'https://cdn.shopify.com/s/files/1/1147/9910/products/LARKIN_STRIPED_21_CLASSICDESIGN_3D_701b42df-ce7e-49dd-bef9-a4dc4514038f.png',
	'TORTOISE': 'https://cdn.shopify.com/s/files/1/1147/9910/files/LARKIN_ROCKSTARTORTOISE_24_MUSICFEST_3D.png',
} as const;

export const DEFAULT_SWIPER = {
	direction: 'horizontal' as SwiperProps['direction'],
	wrapperTag: 'ul',
	initialSlide: 0,
	slidesPerView: 1,
	spaceBetween: 0,
	loop: true,
	a11y: {
		containerMessage: 'Image carousel',
		prevSlideMessage: 'Previous slide',
		nextSlideMessage: 'Next slide',
		lastSlideMessage: 'This is the last slide; it loops back to the first slide',
	},
	keyboard: { enabled: true },
	zoom: false,
};

export const SHOP_BY_COMPONENT_COPY = {
	color: {
		title: 'Shop Top Frames by Color',
		subtitle: 'Narrow your search with your favorite color',
	},
	design: {
		title: 'Shop Top Frames by Design',
		subtitle: 'Narrow your search with your design',
	},
};

export const COUNTRY_LABELS = {
	'en-US': 'United States',
	'en-CA': 'Canada',
};

export const BUILD_YOUR_OWN_BUNDLE = {
	title: 'Build Your Own Bundle',
	subtitle:
		'Mix and match and save on your faves! Select either a 3 Top or a 4 Top Bundle. Choose your Base Frame shape, then select your Tops. Save 10% on 3 Top Bundles and 15% on 4 Top Bundles.',
	selectSize: 'Select Bundle size',
	selectBase: 'Select your Base Frame',
	selectTop: 'Select Top Frames',
	myTopsTitle: 'Complete your Bundle with some Top Frames!',
};
